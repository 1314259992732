<template>
	<v-select
		ref="select"
		v-model="model"
		chips
		small-chips
		:deletable-chips="model.length > minSelectedItems"
		v-bind="$attrs"
		v-on="listeners">
		<template v-slot:item="{ item, tile }">
			<v-list-tile :avatar="listWithChips" v-bind="tile.props" class="w-select-with-chips" v-on="tile.on">
				<slot name="item-action" v-bind="tile.props">
					<v-list-tile-action>
						<w-checkbox :value="tile.props.value" :disabled="model.length <= minSelectedItems && tile.props.value"></w-checkbox>
					</v-list-tile-action>
				</slot>
				<v-list-tile-content>
					<template v-if="listWithChips">
						<template v-if="item.icon">
							<v-chip  color="transparent" small>
								<v-tooltip top :z-index="999">
									<template v-slot:activator="{ on }">
										<v-avatar
											v-if="item.icon"
											:color="item.color"
											size="22"
											class="mr-1"
											small
											v-on="on"
										>
											<v-icon
												:class="$getContrastedTextColor(item.color)"
												size="12"
											>
												{{ item.icon }}
											</v-icon>
										</v-avatar>
										<span :style="`color: ${item.color};`" v-on="on" v-text="getItemShortText(item)" />
										</template>
										<!-- tooltip for chip -->
										<span :class="$getContrastedTextColor(item.color)" v-text="getItemText(item)"/>
									</v-tooltip>
							</v-chip>

						</template>
						<template v-else>
							<v-chip :selected="tile.props.value" :color="item.color" small>
								<v-tooltip top :z-index="999">
									<template v-slot:activator="{ on }">
										<span :class="$getContrastedTextColor(item.color)" v-on="on" v-text="getItemShortText(item)" />
									</template>
									<span :class="$getContrastedTextColor(item.color)" v-text="getItemText(item)"/>
								</v-tooltip>
							</v-chip>
						</template>
					</template>
					<v-list-tile-title v-else :disabled="model.length <= minSelectedItems && tile.props.value">{{ getItemText(item) }}</v-list-tile-title>
				</v-list-tile-content>
			</v-list-tile>
		</template>
		<template v-slot:selection="{ item, index }">
			<template v-if="item.icon">
				<v-chip
					v-if="index <= maxSelectedItemsShowed - 1"
					:close="itemClearable && $attrs.hasOwnProperty('multiple') && !minimized"
					small
					:disabled="model.length <= minSelectedItems"
					@input="removeFromSelection(item)"
				>
					<v-avatar
						v-if="item.icon"
						:color="item.color"
						size="22"
						class="mr-1"
						small
					>
						<v-icon
							:class="$getContrastedTextColor(item.color)"
							size="12"
						>
							{{ item.icon }}
						</v-icon>
					</v-avatar>
					<span :style="`color: ${item.color};`" v-text="getItemShortText(item)" />
				</v-chip>
			</template>
			<template v-else>
				<v-icon v-if="item.icon" :color="item.color" small grey--text>{{ item.icon }}</v-icon>
				<v-chip
					v-if="index <= maxSelectedItemsShowed - 1"
					:close="itemClearable && $attrs.hasOwnProperty('multiple') && !minimized"
					:color="item.color"
					small
					:disabled="model.length <= minSelectedItems"
					@input="removeFromSelection(item)"
				>
					<span :class="$getContrastedTextColor(item.color)" v-text="getItemShortText(item)" />
				</v-chip>
			</template>
			<v-chip v-if="index === maxSelectedItemsShowed" small>
				<span v-if="!minimized">{{ $tc('other_count', model.length - maxSelectedItemsShowed, { count: model.length - maxSelectedItemsShowed }) }}</span>
				<span v-else>{{ $tc('other_count_minimized', model.length - maxSelectedItemsShowed, { count: model.length - maxSelectedItemsShowed }) }}</span>
			</v-chip>
		</template>
	</v-select>
</template>

<script>
import AppModuleGuard from '@/mixins/ModulesGuards/AppModuleGuard'

export default {
	name: 'SelectWithChips',
	mixins: [AppModuleGuard],
	props: {
		maxSelectedItemsShowed: {
			required: false,
			type: Number,
			default: 1
		},
		minSelectedItems: {
			required: false,
			type: Number,
			default: 0
		},
		minimized: {
			required: false,
			type: Boolean,
			default: false
		},
		listWithChips: {
			required: false,
			type: Boolean,
			default: false
		},
		itemClearable: {
			default: true,
			required: false,
			type: Boolean
		},
		itemText: {
			required: false,
			type: String,
			default: null
		},
		itemShortText: {
			required: false,
			type: String,
			default: null
		},
		value: {
			required: true,
			validator: propValue => propValue === (null || undefined) || propValue instanceof Object || Array.isArray(propValue)
		},
		withShortTextTooltip: {
			required: false,
			type: Boolean,
			default: false
		},
	},
	computed: {
		listeners: function () {
			const result = { ...this.$listeners }

			delete result.change
			delete result.input

			return result
		},
		// Model dynamic binding
		model: {
			get: function () {
				return this.value
			},
			set: function (newValue) {
				this.$emit('change', newValue)
				this.$emit('input', newValue)

			}
		},
	},
	methods: {
		getAppEventsActionsMapping: function () {
			return [
				{ event: this.appEvents.CONTEXT_MENU, action: () => this.$refs.select.blur() }
			]
		},
		removeFromSelection: function (item) {
			let copyValue = Object.assign(this.model)
			const itemValueKey = 'item-value'
			if (this.$attrs.hasOwnProperty('return-object') && item) {
				const itemIndex = copyValue.indexOf(item)
				if (itemIndex !== -1) {
					copyValue.splice(itemIndex, 1)
				}
			} else if (this.$attrs.hasOwnProperty(itemValueKey) && item && item.hasOwnProperty(this.$attrs[itemValueKey])) {
				const entry = item[this.$attrs[itemValueKey]]
				const entryIndex = copyValue.indexOf(entry)
				if (entryIndex !== -1) {
					copyValue.splice(entryIndex, 1)
				}
			}
			this.$emit('input', copyValue)
		},
		getItemText: function (item) {
			const text = this.itemText && item?.hasOwnProperty(this.itemText)
					? item[this.itemText]
					: String(item);

			return this.$te(text) ? this.$t(text) : text
		},
		getItemShortText: function (item) {
			if(this.itemShortText && item?.hasOwnProperty(this.itemShortText)) {
				const text = item[this.itemShortText]
				return this.$te(text) ? this.$t(text) : text;
			}

			return this.getItemText(item)
		}
	}
}
</script>

<style>
.w-select-with-chips {
	.v-list__tile__action {
		min-width: 40px;
	}
	.v-chip .v-chip__content {
		cursor: pointer;
	}
}
</style>
